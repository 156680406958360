import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getClasses } from '@agoy/common';
// @ts-ignore
import { ReactComponent as Check } from 'assets/check.svg';
import Typography from '../Typography/Typography';
import When from '../When/When';

const Container = styled.div`
  display: inline-flex;
  align-items: flex-start;
  column-gap: ${({ theme }) => theme.spacing(1)}px;

  p {
    margin: 0;
  }
`;

const baseStyles = css`
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; // prevent shrink of checkbox if label is too long

  input {
    position: absolute;
    border: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: inherit;
    opacity: 0;
    z-index: 1;
  }

  svg {
    fill: #fff;
    opacity: 0;
  }
`;

const DefaultControl = styled.span`
  ${baseStyles}
  width: ${({ theme }) => theme.spacing(3)}px;
  height: ${({ theme }) => theme.spacing(3)}px;
  border-radius: ${({ theme }) => theme.spacing(0.5)}px;
  border: 1px solid ${({ theme }) => theme.palette.border.dark};

  :not(.checked):hover {
    border-color: ${({ theme }) => theme.palette.border.darkGreen};
  }

  &.checked {
    background-color: ${({ theme }) => theme.palette.button.primary.main};
    border-color: ${({ theme }) => theme.palette.button.primary.main};
    transition: background-color 300ms cubic-bezier(0.2, 0, 0, 1);

    svg {
      opacity: 1;
      transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
    }

    :hover {
      background-color: ${({ theme }) => theme.palette.button.primary.hover};
    }
  }

  &.disabled {
    border-color: ${({ theme }) => theme.palette.button.disabled};

    &.checked {
      background-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }
`;

const TaxControl = styled.span`
  ${baseStyles}

  width: 18px;
  height: 18px;
  border: 1px solid #000;
  background-color: #f2f4ff;

  svg {
    width: 13px;
    height: 13px;
    margin-top: 1px;
  }

  :not(.checked):hover {
    border-color: ${({ theme }) => theme.palette.border.darkGreen};
  }

  :hover {
    background-color: color-mix(in srgb, #000 5%, #f2f4ff 100%);
  }

  &.checked {
    transition: background-color 300ms cubic-bezier(0.2, 0, 0, 1);

    svg {
      opacity: 1;
      fill: #000;
      transition: opacity 300ms cubic-bezier(0.2, 0, 0, 1);
    }
  }

  &.disabled {
    border-color: ${({ theme }) => theme.palette.button.disabled};

    &.checked {
      background-color: ${({ theme }) => theme.palette.button.disabled};
    }
  }
`;

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  type?: 'default' | 'tax';
  label?: string;
  children?: React.ReactNode;
  onChange?: (value: boolean) => void;
  onKeyPress?: (event: React.KeyboardEvent) => void;
}

const Checkbox = ({
  checked,
  disabled = false,
  type = 'default',
  label = undefined,
  onChange,
  onKeyPress,
  children,
}: CheckboxProps) => {
  const Control = type === 'tax' ? TaxControl : DefaultControl;

  return (
    <Container>
      <Control className={getClasses({ checked, disabled })}>
        <input
          type="checkbox"
          onChange={(event) => onChange?.(event.target.checked)}
          checked={checked}
          onKeyPress={onKeyPress}
          id={label}
        />
        <Check />
      </Control>
      <When isTrue={!!label}>
        <label htmlFor={label}>
          <Typography margin="none" color={disabled ? 'disabled' : 'onLight'}>
            {label}
          </Typography>
        </label>
      </When>
      <When isTrue={!!children}>
        <Typography margin="none" color={disabled ? 'disabled' : 'onLight'}>
          {children}
        </Typography>
      </When>
    </Container>
  );
};

export default Checkbox;
